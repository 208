.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  margin-bottom: 27px;
}
.email {
  color: black;
  text-decoration: none;
  font-style: normal;
}
.email:hover {
  font-size: 19px;
}
.map {
  padding-bottom: 500px;
  max-width: 505px;
  min-width: 200px;
  width: 100%;
  height: 300px;
}
.App-link {
  color: #61dafb;
}
.engineering {
  font-size: 29px;
  margin-bottom: 5px;
}
.map-toggler {
  color: #2f5585;
}

.map-toggler:hover {
  cursor: pointer;
  font-size: 19px;
}
